<script>
import { mapContainer, geoLocationManager } from '../../../script/LiveLocationManager.js';

export default {
  name: "LiveLocation",

  data() { return {
    waitResponse: false,
    noDataAvailable: false,
    lastUpdateTimeStamp: 0,
    locationHistoryList: []
  }},

  computed: {
    canShowLocation()
    {
      return this.IsGeoLocationSupported() && !this.noDataAvailable && !this.waitResponse;
    },

    lastUpdateText()
    {
      return this.$utils.TimeStampToString(this.lastUpdateTimeStamp);
    },

    historyListLength()
    {
      return this.locationHistoryList.length;
    }
  },
  
  mounted()
  {
    this.Init();
  },

  methods: {
    IsGeoLocationSupported() 
    {
      return geoLocationManager.IsSupported();
    },

    Init()
    {
      mapContainer.Init();
      this.RequestLocation();
      this.RequestLocationHistory();
    },

    RequestLocation()
    {
      this.waitResponse = true;

      geoLocationManager.RequestUserLocation(this.$route.params.id, ({response}) => {
        const { location } = response.data;
        this.OnReceiveLocation(location);
      }
      );
    },

    OnReceiveLocation(location)
    {
      this.waitResponse = false;

      if(!location) {
        this.noDataAvailable = true;
        return;
      }

      this.ShowLocation(location);
    },

    ShowLocation(location)
    {
      this.lastUpdateTimeStamp = location.timestamp;
      let { lat, lng } = location;
      mapContainer.SetLocation(Number(lat), Number(lng));
    },

    RequestLocationHistory()
    {
      geoLocationManager.RequestUserLocationHistory(this.$route.params.id, ({response}) => {
        const { location_history } = response.data;
        this.OnReceiveLocationHistory(location_history)
      }
      );
    },

    OnReceiveLocationHistory(locationHistory)
    {
      this.locationHistoryList = locationHistory;
    },

    SelectLocationHistory(location)
    {
      this.ShowLocation(location);
    }
  }
}
</script>

<template>
<div class="lc-container">
  <disabled-feature></disabled-feature>
  <div v-if="!IsGeoLocationSupported()" class="bordered-box warning-box">
    Gelocation nu este suportat de acest device.
  </div>

  <div class="center-box" v-else-if="waitResponse">
    <loading class="loading"  />
  </div>
  
  <div v-else-if="noDataAvailable" class="bordered-box feedback-box">
    <icon>error_outline</icon>
    No data available
  </div>

  <div class="lc-body" v-show="canShowLocation">
    <div class="row bordered-box info">
      Last location update: {{lastUpdateText}}
    </div>

    <div id="map">
    </div>

    <div class="history-container">
      <div class="row config-row" v-for="location in locationHistoryList" :key="location" @click="SelectLocationHistory(location)">
        {{$utils.TimeStampToString(location.timestamp)}}: {{location.lat}}/{{location.lng}}
      </div>
    </div>

  </div>
</div>
</template>

<style scoped>
.lc-container {
  flex-grow: 1;
  position: relative;
}

.lc-body {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
}

.warning-box {
  border: none;
  font-weight: bold;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.lc-body .info {
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  color: var(--text-color);
}

#map {
  width: 100%; height: 100%;
  border-radius: 20px;
  height: 400px;
}

#map button {
  border-radius: 0px;
}

.loading {
  width: 100%; height: 400px;
}

.history-container {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  margin-top: 30px;
  padding: 10px;
  overflow: auto;
}

.history-container .row {
  width: 100%;
  display: flex;
}
</style>